import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { SideNavItem } from '@atoms';
import { sideNavClickEvent } from './events';
import styles from './styles';

/**
 * Renders a navigation item and its sub-items if any.
 * @param {object} props - Props object.
 * @param {string} props.path - The path of the navigation item.
 * @param {boolean} props.disabled - Whether the navigation item is disabled.
 * @param {boolean} props.external - Whether the navigation item is external.
 * @param {JSX.Element} props.icon - The icon of the navigation item.
 * @param {string} props.title - The title of the navigation item.
 * @param {array} props.subMenus - An array of sub-navigation items.
 * @param {boolean} props.isNew - Whether the navigation item is new.
 * @returns {JSX.Element} - React component representing the navigation item.
 */
const RenderNavItem = ({
  path,
  disabled,
  external,
  icon,
  title,
  subMenus,
  isNew,
  shouldDisplayItem,
  validateRenderNavItem,
}) => {
  const pathname = useLocation().pathname;
  const active = path ? pathname === path : false;
  const subItemActive = subMenus ? subMenus.some((subItem) => subItem.path === pathname) : false;
  const [isOpen, setIsOpen] = useState(subItemActive);

  /** Open and close the submenu */
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SideNavItem
        active={active || subItemActive}
        disabled={disabled}
        external={external}
        icon={icon}
        path={path}
        title={title}
        hasSubMenus={!!subMenus}
        isNew={isNew}
        onClick={() => {
          sideNavClickEvent(t(title));
          handleToggle();
        }}
        isOpen={isOpen}
        validateRenderNavItem={validateRenderNavItem}
      />
      {subMenus && isOpen && (
        <Box component='nav' sx={styles.subNavContainer}>
          <Box component='ul' sx={styles.subStackContainer(true)}>
            {subMenus.filter(shouldDisplayItem).map((subItem) => {
              return (
                <SideNavItem
                  key={subItem.title}
                  active={pathname === subItem.path}
                  disabled={subItem.disabled}
                  external={subItem.external}
                  icon={subItem.icon}
                  path={subItem.path}
                  title={subItem.title}
                  isNew={subItem.isNew}
                  isSubItem
                  onClick={() => sideNavClickEvent(`${t(title)}_${t(subItem.title)}`)}
                  validateRenderNavItem={subItem.validateShowMenu}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

RenderNavItem.propTypes = {
  path: PropTypes.string,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  icon: PropTypes.element,
  title: PropTypes.string,
  subMenus: PropTypes.array,
  isNew: PropTypes.bool,
  pathname: PropTypes.string,
  shouldDisplayItem: PropTypes.func,
  validateRenderNavItem: PropTypes.func,
};

export default RenderNavItem;
