import { FormikProps } from 'formik';
import { blue } from 'theme/colors';
import { Box, FormControl, InputAdornment, Theme, useMediaQuery } from '@mui/material';
import { Input } from '@atoms';
import { AddCustomerProductSchema } from './addCustomerProduct.schema';
import { MAX_LENGTH_COLOR } from './constants';
import styles from './styles';

interface CustomizeColorProductProps {
  formik: FormikProps<AddCustomerProductSchema>;
}

/**
 * Component is responsible for saving custom colors for a product.
 * @param props
 * @param props.formik formik props for add customer product form
 */
const CustomizeColorProduct = ({ formik }: CustomizeColorProductProps) => {
  const {
    values: { colors, productProviderId },
    touched,
    errors,
    handleChange,
    handleBlur,
  } = formik;
  const responsive = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const colorPlaceholders: Record<string, string> = {
    main: blue.main,
    light: blue.light,
    dark: blue.dark,
    contrast: blue.contrastText,
  };

  return (
    <FormControl>
      <Box sx={styles.customizeContainer(responsive)}>
        {(Object.keys(colors) as Array<keyof typeof colors>).map((color) => (
          <Input
            key={color}
            label={color}
            name={`colors.${color}`}
            value={colors[color]}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={colorPlaceholders[color] || ''}
            sx={styles.colorInput}
            error={
              Boolean(errors.colors?.[color]) &&
              typeof touched.colors?.[color] === 'boolean' &&
              touched.colors[color]
            }
            helperText={
              touched.colors?.[color] && typeof errors.colors?.[color] === 'string'
                ? errors.colors[color]
                : undefined
            }
            inputProps={{ maxLength: MAX_LENGTH_COLOR }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Box
                    sx={{
                      ...styles.colorBox,
                      backgroundColor: colors[color] || colorPlaceholders[color],
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        ))}
        <Input
          label='CLIENTS:PRODUCT_PROVIDER_ID'
          name='productProviderId'
          value={productProviderId}
          onChange={handleChange}
          onBlur={handleBlur}
          sx={styles.colorInput}
          error={Boolean(errors.productProviderId) && touched.productProviderId}
          helperText={touched.productProviderId ? errors.productProviderId : ''}
        />
      </Box>
    </FormControl>
  );
};

export default CustomizeColorProduct;
