import { CUSTOMER_SUPPORT, OPERATIONS, OPERATIONS_SUPERVISOR } from '@globalConstants';
import { useSegments } from '@hooks';
import { formatDate, normalizePhoneNumber } from '@utils';
import {
  VALIDATE_BIRTH_DATE,
  VALIDATE_CUIT,
  VALIDATE_DNI,
  VALIDATE_EMAIL,
  VALIDATE_LASTNAME,
  VALIDATE_NAME,
  VALIDATE_PHONE_NUMBER,
  VALIDATE_STRING,
} from '@validations';

export const SEGMENT_EDITOR_ROLES = [OPERATIONS, OPERATIONS_SUPERVISOR, CUSTOMER_SUPPORT];
export const RESENDMAIL_EDITOR_ROLES = [OPERATIONS, OPERATIONS_SUPERVISOR, CUSTOMER_SUPPORT];

export const REGISTER_STATUS = Object.freeze({
  REGISTERED: 'REGISTERED',
  NOT_REGISTERED: 'NOT_REGISTERED',
  DELETED: 'DELETED',
  UNDEFINED: 'UNDEFINED',
});
export const ITEMS = [
  {
    label: 'COMMON:FIRST_NAME',
    getText: (client) => client.firstName,
    getInitialValue: (client) => client.firstName,
    inputName: 'firstName',
    inputValidation: VALIDATE_NAME.required('COMMON:VALIDATIONS:NAME_MUST_BE_NOT_EMPTY'),
    editorRoles: [OPERATIONS, OPERATIONS_SUPERVISOR, CUSTOMER_SUPPORT],
  },
  {
    label: 'COMMON:LAST_NAME',
    getText: (client) => client.lastName,
    getInitialValue: (client) => client.lastName,
    inputName: 'lastName',
    inputValidation: VALIDATE_LASTNAME.required('COMMON:VALIDATIONS:LASTNAME_MUST_BE_NOT_EMPTY'),
    editorRoles: [OPERATIONS, OPERATIONS_SUPERVISOR, CUSTOMER_SUPPORT],
  },
  {
    label: 'COMMON:EMAIL',
    getText: (client) => client?.email,
    getInitialValue: (client) => client?.email,
    inputName: 'email',
    inputValidation: VALIDATE_EMAIL.required('COMMON:VALIDATIONS:REQUIRED_FIELD'),
    editorRoles: [OPERATIONS, OPERATIONS_SUPERVISOR, CUSTOMER_SUPPORT],
  },
  {
    label: 'COMMON:DNI',
    getText: (client) => client.dni,
    getInitialValue: (client) => (client.dni?.includes('Dato no provisto') ? '-' : client.dni),
    inputName: 'dni',
    inputValidation: VALIDATE_DNI,
    editorRoles: [OPERATIONS, OPERATIONS_SUPERVISOR, CUSTOMER_SUPPORT],
    internalFeature: true,
  },
  {
    label: 'COMMON:CUIL',
    getText: (client) => client.cuit,
    getInitialValue: (client) => client.cuit,
    inputName: 'cuit',
    inputValidation: VALIDATE_CUIT,
    editorRoles: [OPERATIONS, OPERATIONS_SUPERVISOR, CUSTOMER_SUPPORT],
  },
  {
    label: 'COMMON:BIRTH_DATE',
    getText: (client) => formatDate(client.birthDate?.replace('Z', ''), { dateOnly: true }) ?? '-',
    getInitialValue: (client) => client.birthDate?.replace('Z', '') ?? null,
    inputName: 'birthDate',
    inputType: 'date',
    inputValidation: VALIDATE_BIRTH_DATE,
    shouldHideEdit: (client) => client?.registrationStatus !== REGISTER_STATUS.REGISTERED,
    editorRoles: [OPERATIONS, OPERATIONS_SUPERVISOR, CUSTOMER_SUPPORT],
  },
  {
    label: 'COMMON:GENDER',
    getText: (client) => client.gender,
    inputName: 'gender',
    getInitialValue: (client) => client.gender,
  },
  {
    label: 'COMMON:PHONE_NUMBER',
    getText: (client) => normalizePhoneNumber(client?.mobileNumber),
    getInitialValue: (client) => normalizePhoneNumber(client?.mobileNumber),
    inputName: 'mobileNumber',
    inputType: 'number',
    inputValidation: VALIDATE_PHONE_NUMBER.required('COMMON:VALIDATIONS:REQUIRED_FIELD'),
    shouldHideEdit: (client) => client?.registrationStatus !== REGISTER_STATUS.REGISTERED,
    editorRoles: [OPERATIONS, OPERATIONS_SUPERVISOR, CUSTOMER_SUPPORT],
  },
  {
    label: 'EMPLOYEES:DETAIL:REGISTER_STATUS',
    getText: (client, t) =>
      t(
        client?.registrationStatus
          ? `EMPLOYEES:DETAIL:STATUS:${client.registrationStatus}`
          : 'EMPLOYEES:DETAIL:STATUS:UNDEFINED'
      ),
    getInitialValue: (client, t) =>
      t(
        client?.registrationStatus
          ? `EMPLOYEES:DETAIL:STATUS:${client.registrationStatus}`
          : 'EMPLOYEES:DETAIL:STATUS:UNDEFINED'
      ),
    inputName: 'registerStatus',
  },

  {
    label: 'COMMON:SEGMENT',
    getText: (client) => client.segment,
    getInitialValue: (client) => client.type,
    inputName: 'segment',
    inputValidation: VALIDATE_STRING.required(),
    inputType: 'select',
    getOptions: useSegments,
    editorRoles: SEGMENT_EDITOR_ROLES,
    parseOption: (item) => ({ text: item.name, value: item.number }),
  },
  {
    label: 'COMMON:USER_ID',
    getText: (client) => client.id,
    getInitialValue: (client) => client.id,
    internalFeature: true,
    inputName: 'userId',
  },
  {
    label: 'COMMON:WALLET_ID',
    getText: (client) => client.walletId,
    getInitialValue: (client) => client.walletId,
    internalFeature: true,
    inputName: 'walletId',
  },

  {
    label: 'EMPLOYEES:DETAIL:ACCOUNT_CREATED_AT',
    getText: (client) => formatDate(client.createdAt),
    getInitialValue: (client) => formatDate(client.createdAt),
    inputName: 'accountCratedAt',
  },
  {
    label: 'EMPLOYEES:DETAIL:LAST_LOGIN_AT',
    getText: (client, t) =>
      client.lastLoginAt
        ? formatDate(client.lastLoginAt)
        : t('EMPLOYEES:DETAIL:EMPTY_LAST_LOGIN_AT'),
    getInitialValue: (client, t) =>
      client.lastLoginAt
        ? formatDate(client.lastLoginAt)
        : t('EMPLOYEES:DETAIL:EMPTY_LAST_LOGIN_AT'),
    inputName: 'lastLoginAt',
  },
];

export const ITEMS_GRID = [
  ['firstName', 'lastName'],
  ['email'],
  ['dni', 'cuit'],
  ['birthDate'],
  ['gender'],
  ['mobileNumber'],
  ['registerStatus'],
  ['segment'],
  ['userId', 'walletId'],
  ['accountCratedAt', 'lastLoginAt'],
];
