import { EVENT_CLICK, FIREBASE, SCREENNAME_INVOICES } from '@globalConstants';

export const EVENT_INVOICES_SELECT_SEGMENT = 'invoices_selectSegment';
export const EVENT_INVOICES_SELECT_MONTH = 'invoices_selectMonth';
export const EVENT_INVOICES_SELECT_YEAR = 'invoices_selectYear';
export const EVENT_INVOICES_DOWNLOAD = 'invoices_download';

/**
 * Event object to send to trackings
 * @param element element clicked
 * @returns an array with the event
 */
export const INVOICES_CLICK_EVENT = (element: string) => [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_INVOICES,
      element: element,
    },
  },
];
