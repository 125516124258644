import { EVENT_CLICK, FIREBASE, SIDENAV_MENU } from '@globalConstants';
import { trackings } from '@utils';

const SIDENAV_HELP_EVENT = 'sideNavMenu_helpButton';

/**
 * Side nav menu on click help button event
 */
export const sideNavHelpButtonClickEvent = () => {
  trackings([
    {
      type: FIREBASE,
      name: EVENT_CLICK,
      props: {
        screen_name: SIDENAV_MENU,
        element: SIDENAV_HELP_EVENT,
      },
    },
  ]);
};

/**
 * Side nav menu on item click event
 * @param itemClicked item clicked name
 */
export const sideNavClickEvent = (itemClicked: string) => {
  trackings([
    {
      type: FIREBASE,
      name: EVENT_CLICK,
      props: {
        screen_name: SIDENAV_MENU,
        element: `sideNavMenu_${itemClicked}`,
      },
    },
  ]);
};
