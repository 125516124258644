import useShowMenuByCategories from './useShowMenuByCategories';
import useShowMenuByCorporateProduct from './useShowMenuByCorporateProduct';

/**
 * @function useShowMenuByCategoriesAndProduct
 * @description This function is used to show the menu based on the corporate product or categories is generic or corporate product.
 * @return {boolean} - Returns a boolean value indicating whether the menu should be shown or not.
 * @example
 * const showMenu = useShowMenuByCategoriesAndProduct();
 */
const useShowMenuByCategoriesAndProduct = (): boolean => {
  const showMenuByCategories = useShowMenuByCategories();
  const showMenuByCorporateProduct = useShowMenuByCorporateProduct();
  return showMenuByCategories || showMenuByCorporateProduct;
};

export default useShowMenuByCategoriesAndProduct;
