import { EVENT_CLICK, FIREBASE, SCREENNAME_HOME } from '@globalConstants';

export const EVENT_HOME_SEE_MOVEMENTS = 'home_seeMovements';

export const HOME_SEE_MOVEMENTS_BUTTON_EVENT = [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_HOME,
      element: EVENT_HOME_SEE_MOVEMENTS,
    },
  },
];
