import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUser } from '@hooks';
import { SelectCompany } from '@screens';
import { useCompanyStore } from '@stores';

/**
 * Private router (only accesible when user is logged in, if not is redirected to login screen)
 */
const PrivateRoute = ({ allowedRoles }) => {
  const { user } = useUser();
  const { selectedCompany } = useCompanyStore();

  if (!user) {
    return <Navigate to='/login' />;
  }

  const isAllowed = user.roles?.some((role) => allowedRoles.includes(role));

  if (!isAllowed) {
    return <Navigate to='/' />;
  }

  if (!user.isInternal && !selectedCompany) {
    return <SelectCompany />;
  }

  return <Outlet />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
  allowedRoles: PropTypes.array,
};

/**
 * checks if the user has permission to see the screen based on a hook
 * @param children screen to render
 * @param validateShowMenu hook to check if needs to navigate to the home
 * @returns the screen to render
 */
export const ProtectedRoute = ({
  children,
  // , validateShowMenu
}) => {
  //TODO fix conditional routes
  // const showmenu = validateShowMenu();
  return children;
  // return showmenu ? children : <Navigate to='/' />;
};
ProtectedRoute.propTypes = {
  children: PropTypes.node,
  validateShowMenu: PropTypes.func,
};

export default PrivateRoute;
