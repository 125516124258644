import { EVENT_CLICK, FIREBASE, SCREENNAME_MY_BALANCE } from '@globalConstants';
import { trackings } from '@utils';

export const EVENT_MY_BALANCE_FILTER_MOVEMENTS = 'myBalance_filterMovements';
export const EVENT_MY_BALANCE_CLEAR_FILTER_MOVEMENTS = 'myBalance_clearFilterMovements';
export const EVENT_MY_BALANCE_EXPORT_MOVEMENTS = 'myBalance_exportMovements';

/**
 * Event object to send to trackings
 * @param element element clicked
 * @returns an array with the event
 */
export const MY_BALANCE_CLICK_EVENT = (element: string) => [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_MY_BALANCE,
      element: element,
    },
  },
];
/**
 * Function to call trackings with prospect creation click event
 * @param element element clicked
 */
export const myBalanceClick = (element: string) => {
  trackings(MY_BALANCE_CLICK_EVENT(element));
};

interface FilterMovements {
  fromDate?: string;
  toDate?: string;
  customerTransactionType?: string;
}
/**
 * Event triggered when user filters
 * @param filters filters values
 */
export const myBalanceFilterMovements = (filters: FilterMovements) => {
  trackings([
    {
      type: FIREBASE,
      name: EVENT_CLICK,
      props: {
        screen_name: SCREENNAME_MY_BALANCE,
        element: EVENT_MY_BALANCE_FILTER_MOVEMENTS,
        ...filters,
      },
    },
  ]);
};
