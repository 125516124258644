import { EVENT_CLICK, FIREBASE, SCREENNAME_CONSUMPTION_BY_COMMERCE } from '@globalConstants';
import { trackings } from '@utils';
import { SearchBoxParams } from './ConsumptionByCommerce';

export const EVENT_CONSUMPTION_BY_COMMERCE_FILTER = 'consumptionByCommerce_filterButton';
export const EVENT_CONSUMPTION_BY_COMMERCE_CLEAR_FILTER = 'consumptionByCommerce_clearFilter';
export const EVENT_CONSUMPTION_BY_COMMERCE_EXPORT = 'consumptionByCommerce_export';

/**
 * Event object to send to trackings
 * @param element element clicked
 * @returns an array with the event
 */
export const CONSUMPTION_BY_COMMERCE_CLICK_EVENT = (element: string) => [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_CONSUMPTION_BY_COMMERCE,
      element: element,
    },
  },
];

/**
 * Event triggered when user filters
 * @param filters filters values
 */
export const consumptionByCommerceFilterConsumptions = (filters: SearchBoxParams) => {
  trackings([
    {
      type: FIREBASE,
      name: EVENT_CLICK,
      props: {
        screen_name: SCREENNAME_CONSUMPTION_BY_COMMERCE,
        element: EVENT_CONSUMPTION_BY_COMMERCE_FILTER,
        ...filters,
      },
    },
  ]);
};
