import useSWR from 'swr';

interface UseProductsByCustomerParams {
  allowRequest?: boolean;
  type?: 'BUSINESS' | 'PERSONAL';
  customerId: number;
}
/**
 * Obtains a list of products by customer_id
 */
const useProductsByCustomer = ({
  allowRequest = true,
  customerId,
  type,
}: UseProductsByCustomerParams) => {
  let URL = `/partner/products/customer/${customerId}`;
  if (type) {
    URL += `?type=${type}`;
  }
  const { data, isLoading, error } = useSWR(() => (allowRequest && customerId ? URL : false), {
    revalidateOnFocus: false,
  });

  return { data, isLoading, error };
};

export default useProductsByCustomer;
