import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { Button } from '@atoms';
import { usePartnerCustomers } from '@hooks';
import { LoginSideImage } from '@layouts';
import { SelectFetch } from '@molecules';
import { useCompanyStore } from '@stores';
import { trackings } from '@utils';
import { SELECT_COMPANY_BUTTON_NEXT_EVENT, SELECT_COMPANY_EVENT } from './events';
import styles from './styles';

/**
 * Screen to render when the user should but hasn't selected a company
 */
const SelectCompany = () => {
  const { setSelectedCompany } = useCompanyStore();
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  /**
   * Submits the form
   * @param {object} values
   */
  function onSubmit() {
    trackings(SELECT_COMPANY_BUTTON_NEXT_EVENT);
    setSelectedCompany(value);
  }

  const disabled = Boolean(!value);

  return (
    <LoginSideImage>
      <Box sx={styles.container}>
        <Typography variant='h1'>{t('LOGIN:SELECT_COMPANY_TITLE')}</Typography>
        <Typography fontWeight='400' color='gray.90' variant='h4'>
          {t('LOGIN:SELECT_COMPANY_BODY')}
        </Typography>
        <Box component={'form'} marginY={'1rem'}>
          <SelectFetch
            getOptions={usePartnerCustomers}
            value={value}
            placeholder={t('CLIENTS:SELECT_COMPANY')}
            parseOption={(item) => ({ value: item.id, text: item.legalName })}
            onChange={(e) => {
              trackings(SELECT_COMPANY_EVENT);
              setValue(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ alignSelf: 'flex-end' }}>
          <Button
            disabled={disabled}
            variant='contained'
            text='COMMON:CONTINUE'
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </LoginSideImage>
  );
};

SelectCompany.propTypes = {};

export default SelectCompany;
