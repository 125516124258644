import { EVENT_CLICK, EVENT_ERROR, FIREBASE, SCREENNAME_EMPLOYEE_LIST } from '@globalConstants';
import { trackings } from '@utils';

export const EVENT_EMPLOYEE_LIST_FILTER_ACCOUNTS = 'employeeList_filterAccounts';
export const EVENT_EMPLOYEE_LIST_CLEAR_FILTER_ACCOUNTS = 'employeeList_clearFilterAccounts';
export const EVENT_EMPLOYEE_LIST_EXPORT_ACCOUNTS = 'employeeList_exportAccounts';
export const EVENT_EMPLOYEE_LIST_MULTIPLE_CUIL = 'employeeList_multipleCuil';
export const EVENT_EMPLOYEE_LIST_PROSPECT_BUTTON = 'employeeList_prospectButton';
export const EVENT_EMPLOYEE_LIST_DISPERSE_BUTTON = 'employeeList_disperseButton';

export const EVENT_EMPLOYEE_LIST_DISPERSE_BALANCE_CONFIRM = 'employeeList_disperseBalanceConfirm';
export const EVENT_EMPLOYEE_LIST_DISPERSE_CANCEL = 'employeeList_disperseCancel';
export const EVENT_EMPLOYEE_LIST_DISPERSE_CONFIRM = 'employeeList_disperseConfirm';
export const EVENT_EMPLOYEE_LIST_DISPERSE_ERROR = 'employeeList_disperseErrorConfirmButton';

export const EVENT_EMPLOYEE_LIST_BALANCE_RECOVERY_CONFIRM = 'employeeList_balanceRecoveryConfirm';
export const EVENT_EMPLOYEE_LIST_BALANCE_RECOVERY_CANCEL = 'employeeList_balanceRecoveryCancel';

/**
 * Event object to send to trackings
 * @param element element clicked
 * @returns an array with the event
 */
export const EMPLOYEE_LIST_CLICK_EVENT = (element: string) => [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_EMPLOYEE_LIST,
      element: element,
    },
  },
];
/**
 * Event object to send to trackings
 * @param element element clicked
 * @returns an array with the event
 */
export const EMPLOYEE_LIST_DISPERSE_ERROR_EVENT = (error: string) => [
  {
    type: FIREBASE,
    name: EVENT_ERROR,
    props: {
      screen_name: SCREENNAME_EMPLOYEE_LIST,
      error,
    },
  },
];
/**
 * Function to call trackings with prospect creation click event
 * @param element element clicked
 */
export const employeeListClick = (element: string) => {
  trackings(EMPLOYEE_LIST_CLICK_EVENT(element));
};

interface FilterMovements {
  cuit: string;
  dni: string;
  firstName: string;
  lastName: string;
  walletId: string;
  email: string;
  product: string;
  categories: [];
  customerId: string;
}
/**
 * Event triggered when user filters
 * @param filters filters values
 */
export const employeeListFilterMovements = (filters: FilterMovements) => {
  trackings([
    {
      type: FIREBASE,
      name: EVENT_CLICK,
      props: {
        screen_name: SCREENNAME_EMPLOYEE_LIST,
        element: EVENT_EMPLOYEE_LIST_FILTER_ACCOUNTS,
        ...filters,
      },
    },
  ]);
};
