import { useMemo } from 'react';
import { OPERATIONS, OPERATIONS_SUPERVISOR } from '@globalConstants';
import useCustomerId from './useCustomerId';
import useProductsByCustomer from './useProductsByCustomer';
import useRoles from './useRoles';
import useUser from './useUser';

/**
 * @function useShowMenuByCorporateProduct
 * @description This function is used to show the menu based on the corporate product and if the selected customerId is not included in hidePersonalMovements array.
 * @return {boolean} - Returns a boolean value indicating whether the menu should be shown or not.
 * @example
 * const showMenu = useShowMenuByCorporateProduct();
 */
const useShowMenuByCorporateProduct = () => {
  const { validateRoles } = useRoles();
  const isOperationRole = validateRoles([OPERATIONS, OPERATIONS_SUPERVISOR]);
  const { user } = useUser();

  const { customerId } = useCustomerId();
  const { data: productsData } = useProductsByCustomer({
    allowRequest: !isOperationRole,
    type: 'BUSINESS',
    customerId: customerId,
  });

  const hidePersonalMovements = useMemo(() => {
    const hidePersonalMovementsArray: [number] = user?.metadata?.hidePersonalMovements ?? [];
    return !hidePersonalMovementsArray.some((item) => item === customerId);
  }, [user, customerId]);

  if (isOperationRole) {
    return true;
  }

  return productsData?.length > 0 && hidePersonalMovements;
};

export default useShowMenuByCorporateProduct;
