import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@atoms';
import { useCustomerId, useAreasByCustomer } from '@hooks';

interface AreasProps {
  value: string;
  onChange: (value: string) => void;
  onClear?: () => void;
  optionWithoutAreas?: boolean;
  disabled?: boolean;
}

/**
 * Areas component for selecting areas associated with a customer
 * @component
 *
 * @example
 * <Areas
 *   value={selectedArea}
 *   onChange={handleAreaChange}
 *   onClear={handleClear}
 *   disabled={false}
 *   optionWithoutAreas
 * />
 */
const SelectAreas = ({
  value,
  onChange,
  optionWithoutAreas = false,
  onClear,
  disabled = false,
}: AreasProps): JSX.Element => {
  const { t } = useTranslation();
  const { customerId } = useCustomerId();
  const { data: areas } = useAreasByCustomer({
    customerId: customerId,
  });

  const areasAux = [
    ...(optionWithoutAreas ? [{ name: '', fantasyName: t('COMMON:OPTION_WITHOUT_AREAS') }] : []),
    ...areas,
  ];

  return (
    <Autocomplete
      key={String(customerId)}
      onChange={onChange}
      value={value}
      items={areasAux}
      placeholder={t('COMMON:AREA_LABEL')}
      getOptionLabel={(value) => value.label}
      parseOption={(value) => ({ label: value.fantasyName, id: value.name })}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={areasAux.length === 0 || disabled}
      onClear={onClear}
    />
  );
};

export default SelectAreas;
