import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from '@emotion/react';
import { IconSquare, IconSquareCheck } from '@tabler/icons-react';
import { Button, Alert } from '@atoms';
import {
  useSessionStorageState,
  useSelectedCompanyDetails,
  useUserSegments,
  useCreateProspect,
  useUser,
  useURLparams,
  useCustomerId,
} from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { Table } from '@molecules';
import { ProspectsConfirmationModal } from '@organisms';
import { useSegmentStore, useCompanyStore } from '@stores';
import { trackings } from '@utils';
import { INITIAL_VALUES, COLUMNS, COLUMNS_WITH_TOOLTIP } from './constants';
import {
  EVENT_PROSPECT_CREATION_ACCEPT_ACCOUNT,
  EVENT_PROSPECT_CREATION_ADD_NEW_ACCOUNT,
  EVENT_PROSPECT_CREATION_CANCEL_ACCOUNT,
  EVENT_PROSPECT_CREATION_DELETE_ACCOUNT,
  EVENT_PROSPECT_CREATION_SEND_ACCOUNT,
  PROSPECT_CREATION_ADD_ACCOUNT_CLICK_EVENT,
  prospectCreationClick,
} from './events';
import QuickCreationModal from './QuickCreationModal';
import styles from './styles';
import useProspectCreationSelectors from '../prospectUpload/useProspectCreationSelectors';
/**
 * Prospect Creation Screen Component
 * @returns {JSX.Element} React component
 */
const ProspectCreationScreen = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [data, setData] = useSessionStorageState('ProspectCreationScreen', []);
  const [open, setOpen] = useState(true);
  const [sendEmail, setSendEmail] = useState(true);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const { showSelectedSegmentInConfirmation } = useProspectCreationSelectors();
  const { selectedSegment, setSelectedSegment } = useSegmentStore();
  const { setURLparams } = useURLparams();
  const { setSelectedCompany } = useCompanyStore();
  const { customerId } = useCustomerId();
  const { data: companyDetails } = useSelectedCompanyDetails(customerId);
  const { data: SegmentsDetails } = useUserSegments();
  const { isLoading, createProspect, confirmCreateProspect } = useCreateProspect();
  const [confirmProspects, setConfirmProspects] = useState({
    openModal: false,
    id: null,
    total: null,
  });
  const { palette } = useTheme();
  const { shouldShowCompanySelector } = useProspectCreationSelectors();
  const shouldShowSegmentSelector = user.isInternal || SegmentsDetails?.length > 1;
  const segment = SegmentsDetails?.find((item) => item.number === selectedSegment);
  const segmentName = segment?.name;
  const companyName = companyDetails?.description;
  /** This function sets a default segment and company when there´s already an account added */
  useEffect(() => {
    if (!selectedSegment && data.length > 0 && shouldShowSegmentSelector)
      setSelectedSegment(data[0].segment.value);
    if (!customerId && data.length > 0 && shouldShowCompanySelector)
      user.isInternal
        ? setURLparams({ customerId: data[0].company.value })
        : setSelectedCompany(data[0].company.value);
    if (data.length > 0) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /** This function sets a default segment when the segments list as only one.
   *  Also cleans up the state when the segments list change */
  useEffect(() => {
    setSelectedSegment(null);
    if (!shouldShowSegmentSelector && SegmentsDetails?.length > 0) {
      setSelectedSegment(SegmentsDetails[0].number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SegmentsDetails]);
  /** Close the modal */
  const handleClose = () => setOpen(false);

  /** Open the modal */
  const handleOpen = () => setOpen(true);

  /**
   * Handle change event for form data
   * @param {object} values - Updated form values
   */
  const handleChange = (values) => {
    trackings(PROSPECT_CREATION_ADD_ACCOUNT_CLICK_EVENT({ ...values, segmento: segmentName }));
    setData([
      ...data,
      {
        ...values,
        segment: { value: selectedSegment, name: segmentName },
        company: { value: customerId, name: companyName },
      },
    ]);
    const { Producto, Rubro } = values;
    setInitialValues({ ...initialValues, Producto, Rubro });
  };

  /**
   * Remove data item from the list
   * @param {number} index - Index of the item to remove
   */
  const handleRemoveData = (index) => {
    prospectCreationClick(EVENT_PROSPECT_CREATION_DELETE_ACCOUNT);
    setData(data.filter((_, i) => i !== index));
    data.length === 1 && handleOpen();
  };

  /**
   * Function for sending data when the service is ready, while mocked.
   * @returns {Promise<void>}
   */
  const handelSendData = async () => {
    prospectCreationClick(EVENT_PROSPECT_CREATION_SEND_ACCOUNT);
    const prospectsData = data.map((item) => ({
      ...item,
      Rubro: item.Rubro.join(', '),
      Area: item.Area?.id,
    }));
    const { prospectId, total } = await createProspect({
      segment: segmentName,
      customerId: customerId,
      prospectsData,
      notification: sendEmail,
    });

    if (prospectId && total) {
      setConfirmProspects({
        openModal: true,
        id: prospectId,
        total,
      });
    } else {
      setIsError(true);
    }
  };

  /**
   * Handles confirmation of prospect creation.
   * @param {boolean} confirm - Confirmation status.
   * @returns {Promise<void>}
   */
  const handleConfirmation = async (confirm) => {
    prospectCreationClick(
      confirm ? EVENT_PROSPECT_CREATION_ACCEPT_ACCOUNT : EVENT_PROSPECT_CREATION_CANCEL_ACCOUNT
    );
    const data = await confirmCreateProspect({
      prospectId: confirmProspects.id,
      confirm,
    });
    if (data) {
      if (confirm) {
        setSendEmail(true);
        setIsSuccess(true);
        setData([]);
        setOpen(true);
      }
    } else {
      setIsError(true);
    }

    setConfirmProspects({ id: null, openModal: false, count: null });
  };

  const disabledCreateProspect = !selectedSegment || !customerId;

  const disabledSendProspect = data.length === 0 || disabledCreateProspect;

  /**
   * handle on change of send email checkbox
   * @param {*} event Check on change event
   */
  const handleSendEmail = (event) => setSendEmail(event.target.checked);

  /**
   * Add new colaborator button on click
   */
  const addNewColaboratorOnClick = () => {
    prospectCreationClick(EVENT_PROSPECT_CREATION_ADD_NEW_ACCOUNT);
    handleOpen();
  };

  return (
    <MainMenuLayout>
      {open ? (
        <FullWidthContent>
          <QuickCreationModal
            data={data}
            open={open}
            onClose={handleClose}
            onChange={handleChange}
            initialValues={initialValues}
          />
        </FullWidthContent>
      ) : (
        <FullWidthContent>
          <Stack direction='row' justifyContent='flex-end'>
            <Button
              text='PROSPECTS:QUICK_CREATION_MODAL:TITLE'
              variant='contained'
              color='primary'
              onClick={addNewColaboratorOnClick}
              size='large'
            />
          </Stack>
          <Table
            showTooltip={COLUMNS_WITH_TOOLTIP}
            disableControls
            items={data}
            columns={COLUMNS(shouldShowCompanySelector, shouldShowSegmentSelector, user.isInternal)}
            onDeleteRow={handleRemoveData}
          />
          {user.isInternal && (
            <BoxContainer sx={styles.checkboxContainer}>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checkedIcon={<IconSquareCheck />}
                    icon={<IconSquare />}
                    style={{ color: palette.primary.main }}
                  />
                }
                checked={sendEmail}
                onChange={handleSendEmail}
                label={t('PROSPECTS:ADD_PROSPECTS_SEND_EMAIL')}
              />
            </BoxContainer>
          )}
          <Stack direction='row' justifyContent='flex-end' paddingTop={2}>
            <Button
              variant='contained'
              onClick={handelSendData}
              content={t('PROSPECTS:SEND_DATA')}
              disabled={disabledSendProspect || isLoading}
              isLoading={isLoading}
            />
          </Stack>
        </FullWidthContent>
      )}
      <ProspectsConfirmationModal
        companyName={companyName}
        segmentName={segmentName}
        confirmProspects={confirmProspects}
        disableButton={isLoading}
        handleConfirmation={handleConfirmation}
        isLoading={isLoading}
        showSelectedSegmentInConfirmation={showSelectedSegmentInConfirmation}
      />
      <Alert
        modalVisible={isSuccess}
        handleClose={() => {
          setIsSuccess(false);
        }}
        variant='success'
        title={'PROSPECTS:SUCCESS_TITLE'}
        description={'PROSPECTS:SUCCESS_BODY'}
      />
      <Alert
        modalVisible={isError}
        handleClose={() => setIsError(false)}
        variant='error'
        title={'COMMON:ERRORS:GENERIC_ERROR_TITLE'}
        description={'COMMON:ERRORS:DEFAULT_ERROR'}
      />
    </MainMenuLayout>
  );
};

export default ProspectCreationScreen;
