export const SCREENNAME_FIREBASETEST = 'firebaseTest_screen';
export const SCREENNAME_PROSPECT_CREATION = 'prospectCreation_screen';
export const SCREENNAME_EMPLOYEE_DETAILS = 'employeeDetails_screen';
export const SCREENNAME_DISPERSION = 'dispersion_screen';
export const SCREENNAME_MY_DISPERSIONS = 'myDispersions_screen';
export const SCREENNAME_CONSUMPTION_LIST = 'consumptionList_screen';
export const SCREENNAME_CONSUMPTION_BY_COMMERCE = 'consumptionByCommerce_screen';
export const SCREENNAME_CONSUMPTION_BY_CATEGORY = 'consumptionByCategory_screen';
export const SCREENNAME_INVOICES = 'invoices_screen';
export const SCREENNAME_SELECT_COMPANY = 'selectCompany_screen';
export const SCREENNAME_LOGIN = 'login_screen';
export const SCREENNAME_HOME = 'home_screen';
export const SIDENAV_MENU = 'sidenav_menu';
export const SCREENNAME_MY_BALANCE = 'myBalance_screen';
export const SCREENNAME_EMPLOYEE_LIST = 'employeeList_screen';
