import { EVENT_CLICK, FIREBASE, SCREENNAME_CONSUMPTION_BY_CATEGORY } from '@globalConstants';
import { trackings } from '@utils';
import { SearchBoxParams } from './ConsumptionByCategory';

export const EVENT_CONSUMPTION_BY_CATEGORY_FILTER = 'consumptionByCategory_filterButton';
export const EVENT_CONSUMPTION_BY_CATEGORY_CLEAR_FILTER = 'consumptionByCategory_clearFilter';
export const EVENT_CONSUMPTION_BY_CATEGORY_EXPORT = 'consumptionByCategory_export';

/**
 * Event object to send to trackings
 * @param element element clicked
 * @returns an array with the event
 */
export const CONSUMPTION_BY_CATEGORY_CLICK_EVENT = (element: string) => [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_CONSUMPTION_BY_CATEGORY,
      element: element,
    },
  },
];

/**
 * Event triggered when user filters
 * @param filters filters values
 */
export const consumptionByCategoryFilterConsumptions = (filters: SearchBoxParams) => {
  trackings([
    {
      type: FIREBASE,
      name: EVENT_CLICK,
      props: {
        screen_name: SCREENNAME_CONSUMPTION_BY_CATEGORY,
        element: EVENT_CONSUMPTION_BY_CATEGORY_FILTER,
        ...filters,
      },
    },
  ]);
};
