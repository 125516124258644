import { useEffect } from 'react';

declare global {
  interface Window {
    hj?: ((...args: unknown[]) => void) & { q?: unknown[] };
    _hjSettings?: { hjid: number; hjsv: number };
  }
}
/**
 * Hotjar script for tracking user behavior
 */
const HotjarScript = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    console.log('Hotjar script initializing...');

    window.hj =
      window.hj ||
      Object.assign(
        (...args: unknown[]) => {
          (window.hj!.q = window.hj!.q || []).push(args);
        },
        { q: [] as unknown[] }
      );

    window._hjSettings = { hjid: 3896836, hjsv: 6 };

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
    document.head.appendChild(script);

    script.onload = () => console.log('✅ Hotjar script loaded successfully');
    script.onerror = () => console.error('❌ Failed to load Hotjar script');

    return () => {
      console.log('🔄 Removing Hotjar script...');
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

export default HotjarScript;
