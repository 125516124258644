import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { IconCoin } from '@tabler/icons-react';
import { Button } from '@atoms';
import { useSelectedCompanyDetails, useUser } from '@hooks';
import { CardWithIcon } from '@molecules';
import { RenderByRole } from '@organisms';
import { parseAmount, trackings } from '@utils';
import { HOME_SEE_MOVEMENTS_BUTTON_EVENT } from './events';
import styles from './styles';

/**
 * @returns The Home content for an external user
 */
const ExternalUserHome = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const { data } = useSelectedCompanyDetails();
  const responsiveProps = { xs: 12, sm: 12, md: 12, lg: 12 };
  const navigate = useNavigate();

  return (
    <>
      <Typography variant='h1'>
        {data?.description
          ? t('HOME:WELCOME_TITLE_EXTERNAL', { companyName: data.description })
          : t('COMMON:HELLO')}
      </Typography>
      <Typography variant='h3Regular' color='gray.90'>
        {t('HOME:WELCOME_BODY_EXTERNAL')}
      </Typography>

      <Grid container spacing={2}>
        <Grid item {...responsiveProps} sx={{ maxWidth: { lg: '416px', md: '416px' } }}>
          <CardWithIcon
            cardStyle={!user.isInternal && styles.balanceOnlyCard} // Remove when unhiding the footer button
            subtitle={t('HOME:BALANCE_AVAILABLE')}
            icon={
              <Box sx={styles.iconBox}>
                <IconCoin color={'white'} size='2rem' />
              </Box>
            }
            footer={
              <RenderByRole>
                <Button
                  onClick={() => {
                    trackings(HOME_SEE_MOVEMENTS_BUTTON_EVENT);
                    navigate('/saldo');
                  }}
                  variant='link'
                  text='COMMON:SEE_MOVEMENTS'
                />
              </RenderByRole>
            }
          >
            <Typography variant='h1'>
              {data?.balance ? parseAmount(data.balance) : t('COMMON:NO_BALANCE')}
            </Typography>
          </CardWithIcon>
        </Grid>
      </Grid>
    </>
  );
};

export default ExternalUserHome;
