import { EVENT_CLICK, FIREBASE, SCREENNAME_CONSUMPTION_LIST } from '@globalConstants';
import { trackings } from '@utils';
import { ConsumptionParams } from './types';

export const EVENT_CONSUMPTION_LIST_FILTER = 'consumptionList_filterButton';
export const EVENT_CONSUMPTION_LIST_CLEAR_FILTER = 'consumptionList_clearFilter';
export const EVENT_CONSUMPTION_LIST_EXPORT = 'consumptionList_export';

/**
 * Event object to send to trackings
 * @param element element clicked
 * @returns an array with the event
 */
export const CONSUMPTION_LIST_CLICK_EVENT = (element: string) => [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_CONSUMPTION_LIST,
      element: element,
    },
  },
];

/**
 * Event triggered when user filters
 * @param filters filters values
 */
export const consumptionListFilterConsumptions = (filters: ConsumptionParams) => {
  trackings([
    {
      type: FIREBASE,
      name: EVENT_CLICK,
      props: {
        screen_name: SCREENNAME_CONSUMPTION_LIST,
        element: EVENT_CONSUMPTION_LIST_FILTER,
        ...filters,
      },
    },
  ]);
};
