import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays, format } from 'date-fns';
import { FormikErrors, useFormik } from 'formik';
import { Box, Grid } from '@mui/material';
import { IconFileExport, IconSearch, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Button, DateTimePicker, IconButton, Input } from '@atoms';
import { useCustomerId, useDownloadReceiptByFilters, useURLparams } from '@hooks';
import { BoxContainer } from '@layouts';
import { SelectFetch } from '@molecules';
import { trackings } from '@utils';
import { FIELDS_CONFIG, INITIAL_VALUES, validationSchema } from './constants';
import {
  CONSUMPTION_LIST_CLICK_EVENT,
  consumptionListFilterConsumptions,
  EVENT_CONSUMPTION_LIST_CLEAR_FILTER,
  EVENT_CONSUMPTION_LIST_EXPORT,
} from './events';
import styles from './styles';
import { Consumption, ConsumptionKeys, ConsumptionParams } from './types';

interface FilterCardProps {
  dataLength: number;
  onSubmit: (values: Record<string, string>) => Promise<void>;
}

/**
 * FilterCard component
 * This component is responsible for rendering a filter card
 * with various filter options such as price range, category, rating, etc.
 * It should allow users to apply filters and update the product list accordingly.
 * @param {FilterCardProps} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const FilterCard = ({ dataLength, onSubmit }: FilterCardProps): JSX.Element => {
  const { t } = useTranslation();
  interface consumptionFormparams extends ConsumptionParams {
    customerId?: number;
  }
  const { params, setURLparams } = useURLparams<consumptionFormparams>();
  const { customerId } = useCustomerId();

  const [isExpanded, setIsExpanded] = useState(true);
  /**
   * format date to formik hook
   * @param date
   * @returns
   */
  const formatDateToFormik = (date: string) => format(addDays(new Date(date), 2), 'yyyy-MM-dd');

  const { values, setFieldValue, handleChange, handleSubmit, errors, resetForm } =
    useFormik<ConsumptionParams>({
      initialValues: {
        ...INITIAL_VALUES,
        ...params,
        ...(params.toDate && { toDate: formatDateToFormik(params.toDate) }),
        ...(params.fromDate && {
          fromDate: formatDateToFormik(params.fromDate),
        }),
      },
      validationSchema,
      onSubmit,
      enableReinitialize: true,
    });
  const { isLoading, downloadReceipt } = useDownloadReceiptByFilters();

  /**
   * Toggle the expansion of the filter card
   * @returns void
   */
  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  /**
   * Handle the reset and submit actions
   */
  const handleResetAndSubmit = () => {
    trackings(CONSUMPTION_LIST_CLICK_EVENT(EVENT_CONSUMPTION_LIST_CLEAR_FILTER));
    setURLparams({ customerId: customerId.toString() });
    resetForm({});
  };

  /**
   * Handle the submit and download actions
   */
  const handleSubmitAndDownload = async () => {
    trackings(CONSUMPTION_LIST_CLICK_EVENT(EVENT_CONSUMPTION_LIST_EXPORT));
    await handleSubmit();
    const valuesAux: ConsumptionParams = {};
    (Object.keys(values) as [keyof ConsumptionParams]).forEach((item) => {
      if (values[item]) valuesAux[item] = values[item];
    });
    downloadReceipt({
      ...valuesAux,
      ...(values.toDate && { toDate: format(new Date(values.toDate), 'yyyy-MM-dd') }),
      ...(values.fromDate && { fromDate: format(new Date(values.fromDate), 'yyyy-MM-dd') }),
    });
  };

  return (
    <BoxContainer>
      <Box sx={styles.container}>
        <Grid container spacing={2}>
          {FIELDS_CONFIG.map(({ name, type, label, minDate, isCollapsible, getOptions }) => {
            //TODO: Cast the typing to avoid build errors.
            const value = (values as Consumption)[name as ConsumptionKeys];
            const error = (errors as FormikErrors<ConsumptionParams>)[
              name as keyof ConsumptionParams
            ];
            const helperText = error ? error : undefined;
            return (
              <Grid item xs={6} md={4} key={name}>
                {type === 'datePicker' ? (
                  <DateTimePicker
                    name={name}
                    value={value}
                    error={Boolean(error)}
                    helperText={helperText}
                    handleChange={(date: string) => setFieldValue(name, date)}
                    placeholder={t(label)}
                    disablePast={false}
                    calendarViews={['year', 'month', 'day']}
                    dateOnly={true}
                    fullWidth
                    inputLabel={t(label)}
                    minDate={minDate}
                  />
                ) : type === 'select' ? (
                  <SelectFetch
                    onChange={(item: { target: { value: string } }) =>
                      setFieldValue(name, item?.target?.value)
                    }
                    label={label}
                    error={Boolean(error)}
                    getOptions={getOptions}
                    parseOption={(value: string) => ({ text: value, value })}
                    helperText={helperText}
                    value={value as string}
                  />
                ) : (
                  (!isCollapsible || isExpanded) && (
                    <Input
                      name={name}
                      label={t(label)}
                      value={value}
                      error={Boolean(error)}
                      helperText={helperText}
                      placeholder={t(label)}
                      onChange={handleChange}
                    />
                  )
                )}
              </Grid>
            );
          })}
        </Grid>
        <Box sx={styles.buttonsBox}>
          <Button
            aria-label={t('COMMON:CLEAR_FILTER')}
            text={'COMMON:CLEAR_FILTER'}
            color='primary'
            variant='link'
            onClick={handleResetAndSubmit}
            customStyle={styles.clearFilter}
          />
          <IconButton
            aria-label={t(isExpanded ? 'COMMON:COLLAPSE_FILTERS' : 'COMMON:EXPAND_FILTERS')}
            tooltip={isExpanded ? 'COMMON:COLLAPSE_FILTERS' : 'COMMON:EXPAND_FILTERS'}
            onClick={handleToggle}
            IconComponent={isExpanded ? IconChevronUp : IconChevronDown}
          />
          <IconButton
            aria-label={t('CONSUMPTION:DOWNLOAD')}
            tooltip='CONSUMPTION:DOWNLOAD'
            onClick={handleSubmitAndDownload}
            IconComponent={IconFileExport}
            isLoading={isLoading}
            disabled={!dataLength}
          />
          <IconButton
            aria-label={t('COMMON:SEARCH')}
            tooltip='COMMON:SEARCH'
            onClick={() => {
              consumptionListFilterConsumptions(values);
              handleSubmit();
            }}
            IconComponent={IconSearch}
            variant='contained'
          />
        </Box>
      </Box>
    </BoxContainer>
  );
};

export default FilterCard;
