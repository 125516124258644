import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { useIsAuthReady } from 'config/auth';
import { useRemoteConfigValues, useUser } from '@hooks';
import screens, { LoadingScreen } from '@screens';
import { PrivateRoute } from './index';
import { ProtectedRoute } from './privateRoute';

/**
 * App router
 */
const AppRouter = () => {
  const remoteConfigReady = useRemoteConfigValues();
  const authReady = useIsAuthReady();

  const analytics = getAnalytics();
  const { user } = useUser();

  const isReady = remoteConfigReady && authReady;

  useEffect(() => {
    user?.roles && setUserProperties(analytics, { role: user?.roles });
  }, [user, analytics]);

  return isReady ? (
    <Routes>
      {screens.map(({ component: Component, path, roles, subMenus, validateShowMenu }) =>
        roles ? (
          <>
            {!subMenus ? (
              <Route key={path} element={<PrivateRoute allowedRoles={roles} />}>
                <Route
                  path={path}
                  element={
                    validateShowMenu ? (
                      <ProtectedRoute validateShowMenu={validateShowMenu}>
                        <Component />
                      </ProtectedRoute>
                    ) : (
                      <Component />
                    )
                  }
                />
              </Route>
            ) : (
              subMenus.map(({ component: SubComponent, ...subRoute }) => (
                <Route key={subRoute.path} element={<PrivateRoute allowedRoles={subRoute.roles} />}>
                  <Route
                    path={subRoute.path}
                    element={
                      subRoute.validateShowMenu ? (
                        <ProtectedRoute validateShowMenu={subRoute.validateShowMenu}>
                          <SubComponent />
                        </ProtectedRoute>
                      ) : (
                        <SubComponent />
                      )
                    }
                  />
                </Route>
              ))
            )}
          </>
        ) : (
          <>
            {!subMenus ? (
              <Route
                path={path}
                key={path}
                element={
                  validateShowMenu ? (
                    <ProtectedRoute validateShowMenu={validateShowMenu}>
                      <Component />
                    </ProtectedRoute>
                  ) : (
                    <Component />
                  )
                }
              />
            ) : (
              subMenus.map(({ component: SubComponent, ...subRoute }) => (
                <Route
                  key={subRoute.path}
                  path={subRoute.path}
                  element={
                    subRoute.validateShowMenu ? (
                      <ProtectedRoute validateShowMenu={subRoute.validateShowMenu}>
                        <SubComponent />
                      </ProtectedRoute>
                    ) : (
                      <SubComponent />
                    )
                  }
                />
              ))
            )}
          </>
        )
      )}
    </Routes>
  ) : (
    <LoadingScreen />
  );
};

export default AppRouter;
