import { CardProvider } from 'helpers/hooks/useCustomerAssignableProducts';
import { Yup } from '@validations';

export const registerCompanyInPrismaSchema = Yup.object({
  companyType: Yup.string().required(),
  contactEmail: Yup.string().email().required(),
  fantasyName: Yup.string().required(),
});

export const customerProductSchema = Yup.object({
  id: Yup.string().required(),
  provider: Yup.mixed<CardProvider>().oneOf(['GP', 'PRISMA']).required(),
  name: Yup.string().required(),
  displayName: Yup.string().required(),
  cardType: Yup.string().required(),
});

/**
 * Returns the field validation for a given color field
 * @param name of the color field
 */
const getColorValidation = (colorName: string) =>
  Yup.string()
    .when([colorName], {
      is: (value: string) => value && value.length > 0,
      then: Yup.string().matches(/^#([a-fA-F0-9]{6})$/, 'CLIENTS:ERRORS:INVALID_COLOR_HEX'),
      otherwise: Yup.string().notRequired(),
    })
    .when(['main', 'light', 'dark', 'contrast'], {
      is: (main: string, light: string, dark: string, contrast: string) =>
        main || light || dark || contrast,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    });

export const addCustomerProductSchema = Yup.object({
  customerId: Yup.number().required(),
  customerProducts: Yup.array(customerProductSchema).min(1).required(),
  registerCompanyInPrisma: registerCompanyInPrismaSchema.nullable(),
  colors: Yup.object({}).shape(
    {
      main: getColorValidation('main'),
      light: getColorValidation('light'),
      dark: getColorValidation('dark'),
      contrast: getColorValidation('contrast'),
    },
    [
      ['main', 'main'],
      ['main', 'light'],
      ['main', 'dark'],
      ['main', 'contrast'],
      ['light', 'light'],
      ['light', 'dark'],
      ['light', 'contrast'],
      ['dark', 'dark'],
      ['dark', 'contrast'],
      ['contrast', 'contrast'],
    ]
  ),
  productProviderId: Yup.string().notRequired(),
});

export type AddCustomerProductSchema = Yup.InferType<typeof addCustomerProductSchema>;
export type RegisterCompanyInPrismaSchema = Yup.InferType<typeof registerCompanyInPrismaSchema>;
