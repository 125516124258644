import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { CLIENT, CLIENT_ANALYST, CORPORATE_CLIENT } from '@globalConstants';
import { SideNav, TopNav } from '@molecules';
import { HotjarScript, RenderByRole } from '@organisms';
import styles from './styles';
/**
 *
 * @param {React.ReactNode} children
 * @returns
 */
const MainMenuLayout = ({ children }) => {
  const [openNav, setOpenNav] = useState(false);

  const responsiveGrid = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const basedGrid = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  return (
    <>
      <RenderByRole includeRoles={[CLIENT, CORPORATE_CLIENT, CLIENT_ANALYST]}>
        <HotjarScript />
      </RenderByRole>
      <div style={styles.container(responsiveGrid)}>
        <header style={styles.topNav}>
          <TopNav onNavOpen={() => setOpenNav(true)} />
        </header>
        <sidebar style={styles.sideBar}>
          <SideNav onClose={() => setOpenNav(false)} open={openNav} />
        </sidebar>
        <section style={styles.sectionContainer(basedGrid)}>
          <div style={styles.rootStyle}>{children}</div>
        </section>
      </div>
    </>
  );
};

export default MainMenuLayout;
MainMenuLayout.propTypes = {
  children: PropTypes.node,
};
