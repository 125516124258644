import { EVENT_CLICK, FIREBASE, SCREENNAME_SELECT_COMPANY } from '@globalConstants';

export const EVENT_SELECT_COMPANY_SELECT = 'selectCompany_select';
export const EVENT_SELECT_COMPANY_BUTTON_NEXT = 'selectCompany_buttonNext';

export const SELECT_COMPANY_EVENT = [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_SELECT_COMPANY,
      element: EVENT_SELECT_COMPANY_SELECT,
    },
  },
];
export const SELECT_COMPANY_BUTTON_NEXT_EVENT = [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_SELECT_COMPANY,
      element: EVENT_SELECT_COMPANY_BUTTON_NEXT,
    },
  },
];
