import { EVENT_CLICK, FIREBASE, SCREENNAME_LOGIN } from '@globalConstants';

export const EVENT_LOGIN_START_NOW = 'login_startNowButton';

export const LOGIN_START_NOW_BUTTON_EVENT = [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_LOGIN,
      element: EVENT_LOGIN_START_NOW,
    },
  },
];
