import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectCustomerProduct from 'components/organisms/SelectCustomerProduct';
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import { CustomerAssignableProduct } from 'helpers/hooks/useCustomerAssignableProducts';
import {
  Alert as MUIAlert,
  Box,
  CircularProgress,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Alert, Button } from '@atoms';
import {
  useCompanyNeedsRegistrationStatusInPrisma,
  useAssignPartnerProducts,
  useSelectedCompanyDetails,
} from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { SelectCustomerCard } from '@organisms';
import { AddCustomerProductSchema, addCustomerProductSchema } from './addCustomerProduct.schema';
import { CUSTOMIZATION_PRODUCT_LIMIT } from './constants';
import CustomizeColorProduct from './CustomizeColorProduct';
import RegisterCompanyInPrisma from './RegisterCompanyInPrisma';
import registerCompanyInPrisma from './services/registerCompanyInPrisma';
import styles from './styles';

const REGISTER_COMPANY_INITIAL_VALUES = {
  companyType: undefined,
  contactEmail: undefined,
  fantasyName: undefined,
};

type ProductType = {
  id: string;
  name: string;
  displayName: string;
  provider: string;
};

/**
 * AddCustomerProduct component is responsible for rendering the interface to select a company and assign customer products to a company.
 * It manages the state of the selected company and customer products, and handles the submission of the selected customer products.
 *
 * @component
 * @returns {JSX.Element} The rendered component
 */
function AddCustomerProduct(): JSX.Element {
  const { t } = useTranslation();
  const { data: selectedCompany } = useSelectedCompanyDetails();
  const [customizeProduct, setCustomizeProduct] = React.useState(false);
  const [modalConfirm, setModalConfirm] = React.useState(false);

  const formikProps = useFormik<AddCustomerProductSchema>({
    initialValues: {
      customerId: selectedCompany?.id,
      customerProducts: [],
      registerCompanyInPrisma: null,
      colors: { main: '', light: '', dark: '', contrast: '' },
      productProviderId: '',
    },
    validateOnChange: true,
    validate: (values) => {
      try {
        validateYupSchema(values, addCustomerProductSchema, true, {
          customizeProduct,
          allowCustomization,
        });
      } catch (err) {
        return yupToFormErrors(err);
      }
      return {};
    },
    enableReinitialize: true,
    /** Handles the submit event for assigning `the selected products to the selected company.
     * Resets the products state after submission.
     */
    onSubmit: async function (data, formikHelpers) {
      const customerProducts = data.customerProducts.map((product: ProductType) => {
        const hasColors = Object.values(formikProps.values.colors).every((val) => Boolean(val));
        const hasProductProviderId = Boolean(formikProps.values.productProviderId);

        return {
          id: product.id,
          ...(customizeProduct && {
            colors: hasColors ? { ...formikProps.values.colors } : null,
            productProviderId: hasProductProviderId ? formikProps.values.productProviderId : null,
          }),
        };
      });

      if (
        !hasAtLeastOneProductByPrisma ||
        (hasAtLeastOneProductByPrisma &&
          !isCheckPrismaStatusLoading &&
          !companyNeedsToRegisterInPrismaError &&
          !companyNeedsToRegisterInPrisma)
      ) {
        await assignProduct(selectedCompany.id, {
          products: customerProducts,
        });
        formikHelpers.resetForm();
        return;
      }

      if (!data.registerCompanyInPrisma) {
        return formikHelpers.setFieldValue(
          'registerCompanyInPrisma',
          REGISTER_COMPANY_INITIAL_VALUES
        );
      }

      await registerCompanyInPrisma(selectedCompany.id, {
        email: data.registerCompanyInPrisma.contactEmail,
        legalName: selectedCompany.description,
        tradeName: data.registerCompanyInPrisma.fantasyName,
        identificationType: data.registerCompanyInPrisma.companyType,
      });

      await assignProduct(selectedCompany.id, {
        products: customerProducts,
      });
      formikHelpers.resetForm();
    },
    onReset: (values, formikHelpers) => {
      setCustomizeProduct(false);
      formikHelpers.setFieldValue('customerProducts', []);
      formikHelpers.setFieldValue('colors', formikProps.initialValues.colors);
      formikHelpers.setFieldValue('productProviderId', formikProps.initialValues.productProviderId);
      resetCompanyNeedsToRegisterInPrisma();
    },
  });
  const hasAtLeastOneProductByPrisma = formikProps.values.customerProducts.some(
    (customerProduct: ProductType) => customerProduct.provider === 'PRISMA'
  );

  const allowCustomization =
    formikProps.values.customerProducts.length === CUSTOMIZATION_PRODUCT_LIMIT;

  const hasCustomizationErrors =
    Boolean(formikProps.errors.colors?.main) ||
    Boolean(formikProps.errors.colors?.light) ||
    Boolean(formikProps.errors.colors?.dark) ||
    Boolean(formikProps.errors.colors?.contrast) ||
    Boolean(formikProps.errors.productProviderId);

  const {
    data: companyNeedsToRegisterInPrisma,
    isLoading: isCheckPrismaStatusLoading,
    error: companyNeedsToRegisterInPrismaError,
    reset: resetCompanyNeedsToRegisterInPrisma,
  } = useCompanyNeedsRegistrationStatusInPrisma(hasAtLeastOneProductByPrisma);

  const {
    isLoading,
    isSuccess,
    isError,
    isFailed,
    alertMessage,
    setIsError,
    setIsSuccess,
    setIsFailed,
    assignProduct,
  } = useAssignPartnerProducts();
  const completeForm = selectedCompany && formikProps.values.customerProducts.length > 0;
  const disableButton =
    !completeForm ||
    (hasAtLeastOneProductByPrisma && Boolean(companyNeedsToRegisterInPrismaError)) ||
    (hasAtLeastOneProductByPrisma &&
      companyNeedsToRegisterInPrisma &&
      (Boolean(formikProps.errors.registerCompanyInPrisma) ||
        !formikProps.values.registerCompanyInPrisma)) ||
    (customizeProduct && allowCustomization && hasCustomizationErrors);

  /**
   * Handles the change event for the SelectProduct component, updating the products state.
   */
  function handleCustomerProductsChange(customerProductsSelected: CustomerAssignableProduct[]) {
    formikProps.setFieldValue('customerProducts', customerProductsSelected);
  }

  /**
   * function to confirm assign product
   */
  const handleModalConfirm = async () => {
    formikProps.handleSubmit();
    setModalConfirm(false);
  };

  return (
    <>
      <MainMenuLayout>
        <FullWidthContent>
          <SelectCustomerCard showCustomerType />
          {formikProps.isSubmitting ? (
            <Box sx={styles.spinnerBox}>
              <CircularProgress />
            </Box>
          ) : selectedCompany ? (
            <BoxContainer>
              <Typography variant='h4'>{t('COMMON:ADD_PRODUCT')}</Typography>

              <SelectCustomerProduct
                customerProducts={formikProps.values.customerProducts}
                onChange={handleCustomerProductsChange}
                customerId={selectedCompany.id}
              />
              {allowCustomization && (
                <Box display='flex'>
                  <FormControlLabel
                    label={t('CLIENTS:CUSTOM_COLOR')}
                    labelPlacement='start'
                    control={
                      <Switch
                        checked={customizeProduct}
                        onChange={() => setCustomizeProduct((prev) => !prev)}
                        sx={{ ml: 1 }}
                      />
                    }
                    sx={{ ml: 0 }}
                  />
                </Box>
              )}

              {customizeProduct && allowCustomization && (
                <CustomizeColorProduct formik={formikProps} />
              )}
              <Box>
                {companyNeedsToRegisterInPrisma ? (
                  <RegisterCompanyInPrisma formik={formikProps} />
                ) : null}
              </Box>

              {hasAtLeastOneProductByPrisma && Boolean(companyNeedsToRegisterInPrismaError) && (
                <MUIAlert severity='error'>
                  {t('CLIENTS:ERRORS.REGISTER_COMPANY_IN_PRISMA')}
                </MUIAlert>
              )}
              <Box sx={styles.buttonBox}>
                <Button
                  customStyle={styles.button}
                  color='primary'
                  disabled={disableButton}
                  isLoading={isLoading || isCheckPrismaStatusLoading}
                  text={t('COMMON:ADD')}
                  type='submit'
                  variant='contained'
                  onClick={() => setModalConfirm(true)}
                />
              </Box>
            </BoxContainer>
          ) : null}
        </FullWidthContent>
        <Alert
          variant={'warning'}
          modalVisible={modalConfirm}
          handleClose={() => setModalConfirm(false)}
          handleSubmit={handleModalConfirm}
          closeButtonText={t('COMMON:CANCEL')}
          title={t('COMMON:CONFIRM')}
          submitLoading={isLoading || isCheckPrismaStatusLoading}
          description={t('CLIENTS:CONFIRM_ASSIGN_PRODUCT:MODAL_DESCRIPTION')}
          details={
            formikProps.values.customerProducts.map(
              (product: ProductType) => product.displayName
            ) as never[]
          }
        />
        <Alert
          description={alertMessage.description}
          details={alertMessage.details}
          modalVisible={isError || isFailed || isSuccess}
          title={alertMessage.title}
          variant={alertMessage.variant}
          handleClose={() => {
            setIsError(false);
            setIsFailed(false);
            setIsSuccess(false);
          }}
        />
      </MainMenuLayout>
    </>
  );
}

export default AddCustomerProduct;
