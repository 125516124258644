import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { IconHelp, IconMessageChatbot } from '@tabler/icons-react';
import { ZEN_DESK_ENABLED } from 'config/zenDesk';
import { Button } from '@atoms';
import { HELP_MANUAL_LINK } from '@globalConstants';
import { useUser } from '@hooks';
import screens from '@screens';
import NubizLogo from '@assets/svg/nubizLogo.svg';
import { sideNavHelpButtonClickEvent } from './events';
import RenderNavItem from './RenderNavItem';
import styles from './styles';

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {()=>void | () => Promise<void>} prop.onClose
 * @returns
 */
const SideNav = (props) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { user } = useUser();
  const { t } = useTranslation();

  /**
   * Check if a nav item should be displayed to the user
   * @param {object} item
   * @returns {boolean}
   */
  const shouldDisplayItem = (item) => {
    if (item.onMenu) {
      if (!item.roles || user.roles.some((role) => item.roles.includes(role))) {
        if (item.permissions) {
          return item.permissions.every((value) => user.permissions.includes(value));
        } else {
          return true;
        }
      }
    }
    return false;
  };

  /** */
  const openZendeskChat = () => {
    if (window.zE) {
      window.zE('messenger', 'show');
      window.zE('messenger', 'open');
    }
  };

  const content = (
    <>
      <Box component='a' href='/' sx={styles.iconBox}>
        <img src={NubizLogo} alt='Logo' style={styles.img} />
      </Box>
      <Box component='nav' sx={styles.navContainer}>
        <Box component='ul' sx={styles.stackContainer}>
          {screens.filter(shouldDisplayItem).map((item) => {
            return (
              <RenderNavItem
                disabled={item.disabled}
                external={item.external}
                icon={item.icon}
                key={item.title}
                path={item.path}
                title={item.title}
                isNew={item?.isNew}
                subMenus={item?.subMenus}
                shouldDisplayItem={shouldDisplayItem}
                validateRenderNavItem={item?.validateShowMenu}
              />
            );
          })}
        </Box>
      </Box>
      <Box sx={styles.faqContainer}>
        <a
          rel='noreferrer noopener'
          href={HELP_MANUAL_LINK}
          target='_blank'
          style={styles.faqButtonStyle.box}
          onClick={sideNavHelpButtonClickEvent}
        >
          <Button
            customStyle={styles.faqButtonStyle}
            startIcon={<IconHelp />}
            text={t('FAQ:HELP')}
            variant='text'
          />
        </a>
        {ZEN_DESK_ENABLED && (
          <Button
            customStyle={styles.faqButtonStyle}
            onClick={openZendeskChat}
            startIcon={<IconMessageChatbot />}
            text={t('FAQ:SUPPORT')}
            variant='text'
          />
        )}
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor='left'
        open
        PaperProps={{
          sx: styles.paperProps(lgUp),
        }}
        variant='permanent'
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor='left'
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: styles.paperProps(lgUp),
      }}
      sx={styles.mdDrawer}
      variant='temporary'
    >
      {content}
    </Drawer>
  );
};

SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
export default SideNav;
