import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material';
import { Button } from '@atoms';
import { VARIANTS } from './constants';
import styles from './style';
/**
 * Renders a modal with confirm/reject buttons, using material ui Dialog component
 */
const Alert = ({
  title = '',
  description = '',
  details,
  content,
  modalVisible,
  setModalVisible = () => {},
  closeButtonText = 'COMMON:UNDERSTOOD',
  handleClose,
  confirmButtonText = 'COMMON:CONFIRM',
  handleSubmit,
  variant = 'error',
  widePaper = false,
  disabled = false,
  submitLoading,
}) => {
  /**
   * Function to close modal by default when press hardware back or backdrop
   */
  const defaultCloseModal = () => setModalVisible((value) => !value);
  const { t } = useTranslation();

  const { iconColor, iconBoxColor, icon } = VARIANTS[variant] || VARIANTS.error;

  const Icon = styled(icon)('div');

  return (
    <Dialog
      open={modalVisible}
      onClose={handleClose ?? defaultCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{ sx: styles.paper(widePaper) }}
    >
      <Box>
        <Box sx={styles.iconBox(iconBoxColor)}>
          <Icon sx={styles.icon(iconColor)} />
        </Box>
      </Box>
      <Box sx={styles.contentContainer}>
        <DialogTitle id='alert-dialog-title'>{t(title)}</DialogTitle>
        <DialogContent>
          {description && (
            <DialogContentText id='alert-dialog-description'>{t(description)}</DialogContentText>
          )}
          {Array.isArray(details) && details.length > 0 && (
            <DialogContentText id='alert-dialog-description' sx={styles.details}>
              {details.map((item, index) => (
                <li key={index}>{t(item)}</li>
              ))}
            </DialogContentText>
          )}
          {content && content}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disabled}
            customStyle={{ button: styles.button }}
            variant={handleSubmit ? 'outlined' : 'contained'}
            onClick={handleClose ?? defaultCloseModal}
            text={t(closeButtonText)}
          />
          {handleSubmit && (
            <Button
              isLoading={submitLoading}
              disabled={disabled}
              customStyle={{ button: styles.button }}
              variant='contained'
              onClick={handleSubmit}
              text={t(confirmButtonText)}
            />
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.array.string,
  closeButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  variant: PropTypes.string,
  content: PropTypes.node,
  widePaper: PropTypes.node,
  disabled: PropTypes.bool,
  submitLoading: PropTypes.bool,
};

export default Alert;
