import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { IconDownload } from '@tabler/icons-react';
import { Alert, Button } from '@atoms';
import { useInvoices, useUser, useUserSegments } from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { SelectFetch } from '@molecules';
import { downloadFile, createYearsAndMonthsCalendar, trackings } from '@utils';
import { INVOICES_START_YEAR, INVOICES_START_MONTH } from './constants';
import {
  EVENT_INVOICES_DOWNLOAD,
  EVENT_INVOICES_SELECT_MONTH,
  EVENT_INVOICES_SELECT_SEGMENT,
  EVENT_INVOICES_SELECT_YEAR,
  INVOICES_CLICK_EVENT,
} from './events';
import styles from './styles';

const { calendar, years } = createYearsAndMonthsCalendar(INVOICES_START_YEAR, INVOICES_START_MONTH);

/**
 * Screen component for obtaining employee expenses' invoices
 */
const Invoices = () => {
  const [segment, setSegment] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [error, setError] = useState({ title: '', body: '' });

  const { t } = useTranslation();

  const { user } = useUser();
  const shouldSelectSegment = user.isInternal || user.metadata.types.length > 1;

  /**
   * Handle invoice error
   */
  function onError(error) {
    const response = error?.response;
    if (response?.status === 404 && response.data?.code) {
      setError({
        title: 'INVOICES:ERRORS:NOT_FOUND_ERROR_TITLE',
        body: 'INVOICES:ERRORS:NOT_FOUND_ERROR_BODY',
      });
    } else {
      setError({
        title: 'INVOICES:ERRORS:DEFAULT_ERROR_TITLE',
        body: 'INVOICES:ERRORS:DEFAULT_ERROR_BODY',
      });
    }
    setShowErrorModal(true);
  }

  const { data, isLoading, isValidating } = useInvoices(
    { segment: shouldSelectSegment ? segment : user.metadata.types[0], year, month },
    { onError }
  );

  /**
   * Locally downloads the file with the URL obtained
   */
  function handleDownload() {
    trackings(INVOICES_CLICK_EVENT(EVENT_INVOICES_DOWNLOAD));
    downloadFile(data, `${shouldSelectSegment ? `${segment}-` : ''}${month}-${year}`);
  }

  const isLinkLoading = isLoading || isValidating;

  /**
   * Should the button be disabled
   */
  const disableButton = () => {
    if (shouldSelectSegment && !segment) {
      return true;
    }
    if (!data || !month || !year || isValidating) {
      return true;
    }
  };

  /**
   * Handles the change of the year
   */
  const handleChangeYear = (e) => {
    trackings(INVOICES_CLICK_EVENT(EVENT_INVOICES_SELECT_YEAR));
    const selectedYear = e.target.value;
    setYear(selectedYear);
    if (month && !calendar.get(Number(year)).some((m) => Number(m.value) === Number(month))) {
      setMonth('');
    }
  };

  return (
    <MainMenuLayout>
      <FullWidthContent>
        {shouldSelectSegment && (
          <BoxContainer>
            <Typography variant='h4'>{t('COMMON:SEGMENT')}</Typography>
            <SelectFetch
              onChange={(e) => {
                trackings(INVOICES_CLICK_EVENT(EVENT_INVOICES_SELECT_SEGMENT));
                setSegment(e.target.value);
              }}
              getOptions={useUserSegments}
              value={segment}
              parseOption={(item) => ({ text: item.name, value: item.number })}
            />
          </BoxContainer>
        )}
        <BoxContainer topPadding={shouldSelectSegment ? false : true} paperPadding='1.5rem'>
          <Typography variant='h4'>{t('INVOICES:SELECT_PERIOD')}</Typography>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <SelectFetch
              placeholder='COMMON:YEAR'
              value={year}
              onChange={handleChangeYear}
              getOptions={() => ({ data: years })}
              parseOption={(year) => ({ value: String(year), text: year })}
            />
            <SelectFetch
              placeholder='COMMON:MONTH'
              disabled={Boolean(!year)}
              value={month}
              onChange={(e) => {
                trackings(INVOICES_CLICK_EVENT(EVENT_INVOICES_SELECT_MONTH));
                setMonth(e.target.value);
              }}
              getOptions={() => ({
                data: calendar.get(Number(year)),
              })}
            />
          </Box>
          <Button
            isLoading={isLinkLoading}
            disabled={disableButton()}
            content={
              <Box sx={styles.downloadButton}>
                <IconDownload size='1.5rem' />
                {t('COMMON:DOWNLOAD')}
              </Box>
            }
            variant='contained'
            onClick={handleDownload}
            customStyle={{ box: styles.downloadButtonBox }}
          />
        </BoxContainer>
      </FullWidthContent>
      <Alert
        title={error.title}
        description={error.body}
        modalVisible={showErrorModal}
        handleClose={() => setShowErrorModal(false)}
      />
    </MainMenuLayout>
  );
};

export default Invoices;
